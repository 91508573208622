<template>
  <div id="register-analytics">
    <h1>Register analytics</h1>
    <div v-if="!init">Loading...</div>
    <div v-else class="register-analytics-container">
      <div class="completed-container">
        <div class="admin-table completed-table">
          <div class="table-header">
            <span class="time label">Time</span>
            <span class="new-company label">New company?</span>
            <span class="user label">User</span>
            <span class="company label">Company</span>
          </div>
          <div v-for="completed in completedDocs" :key="completed.id">
            <span class="time">{{
              completed.timestamp.toDate() | moment("from", "now")
            }}</span>
            <span class="new-company">{{
              completed.newCompany ? "Yes" : "No"
            }}</span>
            <span
              class="user"
              @click="
                navigateToUser(
                  getUserByVismaId(completed.userId) !== undefined
                    ? getUserByVismaId(completed.userId).uid
                    : ''
                )
              "
              >{{
                getUserByVismaId(completed.userId) !== undefined
                  ? getUserByVismaId(completed.userId).name
                  : "Deleted user"
              }}
              - {{ completed.userId }}</span
            >
            <span
              class="company"
              @click="navigateToCompany(completed.companyId)"
              >{{
                getCompanyNameByVismaId(completed.companyId) !== ""
                  ? getCompanyNameByVismaId(completed.companyId)
                  : "Deleted company"
              }}
              - {{ completed.companyId }}</span
            >
          </div>
        </div>
      </div>
      <div class="detail-container">
        <div v-for="doc in detailDocs" :key="doc.id" class="single-detail">
          <h5 class="device-id">ID: {{ doc.id }}</h5>
          <div
            v-for="step in detailDocSteps(doc.id)"
            :key="step.id"
            class="single-step"
            :class="{
              'arrow-red': step.to === 'loginEmail',
              'arrow-yellow': step.failed === true,
              completed: step.completed === true,
              canceled: step.to === 'loginEmail',
            }"
          >
            <div class="timestamp">
              {{ step.timestampDate | moment("from", "now") }}
            </div>
            <h4 class="header">
              {{ stepHeader(step) }}
            </h4>
            <div class="content">
              <p v-html="stepContent(step)"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "RegisterAnalytics",
  data() {
    return {};
  },
  components: {},
  mounted() {},
  computed: {
    ...mapState({
      init: (state) => state.registerAnalytics.init,
      detailDocs: (state) => state.registerAnalytics.detailDocs,
      completedDocs: (state) => state.registerAnalytics.completedDocs,
    }),
    ...mapGetters("registerAnalytics", ["detailDocSteps"]),
    ...mapGetters("settings", ["registerSegmentNameById"]),
    ...mapGetters("users", ["getUserByVismaId"]),
    ...mapGetters("companies", ["getCompanyNameByVismaId"]),
  },
  methods: {
    navigateToCompany: function (id) {
      this.$router.push({ name: "SingleCompany", params: { id } });
    },
    navigateToUser: function (id) {
      this.$router.push({ name: "SingleUser", params: { id } });
    },
    stepHeader(step) {
      if (step.completed == true) {
        return "Register successful!";
      }
      switch (step.to) {
        case "registerOrg":
          return "Registration started";
        case "registerPostCodeForm":
          return "Check company by postcode";
        case "registerNameSegment":
          return `Name & segment`;
        case "registerContactAddress":
          return "Address & invoice e-mail";
        case "registerDeliveryAddress":
          return "Delivery address";
        case "registerUser":
          return "User details";
        case "loginEmail":
          return "Registration canceled";
        case null:
          return "Validation failed";
        default:
          return step.to;
      }
    },
    stepContent(step) {
      if (step.completed == true) {
        return `Successfully created new user with e-mail: <b>${step.registerData.userEmail}</b>`;
      }

      if (step.failed == false) {
        switch (step.to) {
          case "registerOrg":
            return "User began the registration by pressing the Register new account button.";
          case "registerPostCodeForm":
            return `User entered an org number that matches several existing companies.<br>
                    <br>
                    Org number: <b>${step.registerData.orgNumber}</b>`;
          case "registerNameSegment":
            if (step.from == "registerPostCodeForm") {
              return `Couldn't find company matching post code. Starting a new registration.`;
            } else {
              return `Org number: <b>${step.registerData.orgNumber}</b><br>
                      <br>
                      User entered an org number that doesen't exist yet.`;
            }
          case "registerContactAddress":
            return `Company name: <b>${
              step.registerData.companyName
            }</b><br>Segment: <b>${this.registerSegmentNameById(
              step.registerData.segment
            )}</b>`;
          case "registerDeliveryAddress":
            return `Contact address: <b>${step.registerData.contactAddress}</b><br>
                    Contact post code: <b>${step.registerData.contactPostCode}</b><br>
                    Contact area: <b>${step.registerData.contactArea}</b><br>
                    Invoice e-mail: <b>${step.registerData.invoiceEmail}</b>
                    `;
          case "registerUser":
            if (step.from == "registerOrg") {
              return `User entered an org number that already exists in Visma.<br>
                      <br>
                      Org number: <b>${step.registerData.orgNumber}</b><br>
                      Company: <b>${this.getCompanyNameByVismaId(
                        step.registerData.existingVismaId
                      )}</b>`;
            } else if (step.from == "registerPostCodeForm") {
              return `User first entered an existing org number, and then an existing post code.<br>
                      <br>
                      Company: <b>${this.getCompanyNameByVismaId(
                        step.registerData.existingVismaId
                      )}</b>`;
            } else {
              return `
                      Delivery address: <b>${step.registerData.deliveryAddress}</b><br>
                      Delivery post code: <b>${step.registerData.deliveryPostCode}</b><br>
                      Delivery area: <b>${step.registerData.deliveryArea}</b>
                      `;
            }
          case "loginEmail":
            return "User canceled the registration by hitting the Cancel button.";
          default:
            return "Coming soon";
        }
      } else {
        let returnString = `${step.errorMessage}<br><br>`;
        switch (step.from) {
          case "registerOrg":
            return `${returnString}
                    Org number: <b>${step.registerData.orgNumber}</b>`;
          case "registerNameSegment":
            return `${returnString}
                    Company name: <b>${step.registerData.companyName}</b><br>
                    Segment: <b>${step.registerData.segment}</b>
                    `;
          case "registerContactAddress":
            return `${returnString}
                    Contact address: <b>${step.registerData.contactAddress}</b><br>
                    Contact post code: <b>${step.registerData.contactPostCode}</b><br>
                    Contact area: <b>${step.registerData.contactArea}</b><br>
                    Invoice e-mail: <b>${step.registerData.invoiceEmail}</b>
                    `;
          case "registerDeliveryAddress":
            return `${returnString}
                    Delivery address: <b>${step.registerData.deliveryAddress}</b><br>
                    Delivery post code: <b>${step.registerData.deliveryPostCode}</b><br>
                    Delivery area: <b>${step.registerData.deliveryArea}</b>
                    `;
          case "registerUser":
            return `${returnString}
                    Name: <b>${step.registerData.userName}</b><br>
                    E-mail: <b>${step.registerData.userEmail}</b><br>
                    Phone: <b>${step.registerData.userPhone}</b>
                    `;

          default:
            return "Coming soon";
        }
      }
    },
  },
  created() {
    if (!this.init) {
      this.$store.dispatch("registerAnalytics/bindDetailDocs").then(() => {
        this.$store.dispatch("registerAnalytics/bindCompletedDocs").then(() => {
          this.$store.commit("registerAnalytics/setInit", true);
          console.log(this.detailDocs);
          console.log(this.completedDocs);
        });
      });
    }
  },
};
</script>
