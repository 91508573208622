var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"register-analytics"}},[_c('h1',[_vm._v("Register analytics")]),(!_vm.init)?_c('div',[_vm._v("Loading...")]):_c('div',{staticClass:"register-analytics-container"},[_c('div',{staticClass:"completed-container"},[_c('div',{staticClass:"admin-table completed-table"},[_vm._m(0),_vm._l((_vm.completedDocs),function(completed){return _c('div',{key:completed.id},[_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm._f("moment")(completed.timestamp.toDate(),"from", "now")))]),_c('span',{staticClass:"new-company"},[_vm._v(_vm._s(completed.newCompany ? "Yes" : "No"))]),_c('span',{staticClass:"user",on:{"click":function($event){_vm.navigateToUser(
                _vm.getUserByVismaId(completed.userId) !== undefined
                  ? _vm.getUserByVismaId(completed.userId).uid
                  : ''
              )}}},[_vm._v(_vm._s(_vm.getUserByVismaId(completed.userId) !== undefined ? _vm.getUserByVismaId(completed.userId).name : "Deleted user")+" - "+_vm._s(completed.userId))]),_c('span',{staticClass:"company",on:{"click":function($event){return _vm.navigateToCompany(completed.companyId)}}},[_vm._v(_vm._s(_vm.getCompanyNameByVismaId(completed.companyId) !== "" ? _vm.getCompanyNameByVismaId(completed.companyId) : "Deleted company")+" - "+_vm._s(completed.companyId))])])})],2)]),_c('div',{staticClass:"detail-container"},_vm._l((_vm.detailDocs),function(doc){return _c('div',{key:doc.id,staticClass:"single-detail"},[_c('h5',{staticClass:"device-id"},[_vm._v("ID: "+_vm._s(doc.id))]),_vm._l((_vm.detailDocSteps(doc.id)),function(step){return _c('div',{key:step.id,staticClass:"single-step",class:{
            'arrow-red': step.to === 'loginEmail',
            'arrow-yellow': step.failed === true,
            completed: step.completed === true,
            canceled: step.to === 'loginEmail',
          }},[_c('div',{staticClass:"timestamp"},[_vm._v(" "+_vm._s(_vm._f("moment")(step.timestampDate,"from", "now"))+" ")]),_c('h4',{staticClass:"header"},[_vm._v(" "+_vm._s(_vm.stepHeader(step))+" ")]),_c('div',{staticClass:"content"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.stepContent(step))}})])])})],2)}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-header"},[_c('span',{staticClass:"time label"},[_vm._v("Time")]),_c('span',{staticClass:"new-company label"},[_vm._v("New company?")]),_c('span',{staticClass:"user label"},[_vm._v("User")]),_c('span',{staticClass:"company label"},[_vm._v("Company")])])
}]

export { render, staticRenderFns }